const asSafeString = (str) => {
	return str
		.toLowerCase()
		.replace(/å/g, "a")
		.replace(/æ/g, "a")
		.replace(/ø/g, "o")
		.replace(/\+/g, "plus")
		.replace(/[^\w\s]/g, "")
		.replace(/\s/g, "-");
};

export default function safeUrl(category, brand, title, condition) {
	let urlSegments = [
		category ? (category = asSafeString(category)) : "",
		brand ? (brand = asSafeString(brand)) : "",
		title ? (title = asSafeString(title)) : "",
		condition ? (condition = asSafeString(condition)) : "",
	];

	const filteredSegments = urlSegments.filter((segment) => segment?.length > 0);
	if (filteredSegments.length === 0) return undefined;
	return `/${filteredSegments.join("/")}/`;
}
